<template>
    <div class=" product">
        <div class="container block">
            <div class="row">
                <div class="col-md-5 col-lg-4">
                    <img
                        class="logo mt-md-5"
                        :src="product.logo_img"
                        :alt="product.name"
                        style="height: 70px;"
                    />
                    <br />
                    <h4 class="name">{{ product.name }}</h4>
                    <div class="d-block d-md-none">
                        <img
                            class="main"
                            :src="product.main_img"
                            :alt="product.name"
                        />
                    </div>
                    <div class="s_info" v-html="get_s_info"></div>
                    <div class="info_btn mb-2 text-left">
                        <a
                            class="btn button download_btn"
                            v-if="product.main_video.length > 0"
                            @click="
                                showVideo(
                                    product.main_video,
                                    product.main_embed
                                )
                            "
                        >
                            <i class="fab fa-youtube"></i>
                            &nbsp;{{
                                $store.state.language.product.sample_video
                            }}
                        </a>
                        <a
                            class="btn button download_btn"
                            download
                            target="_blank"
                            v-if="product.main_pdf != ''"
                            :href="product.main_pdf"
                        >
                            <i class="fa fa-download"></i>
                            &nbsp;{{
                                $store.state.language.product.sample_download
                            }}
                        </a>
                    </div>
                    <div
                        class="info_btn mb-5 text-left"
                        v-show="$store.state.web"
                    >
                        <a class="inquiry_btn" @click="add_inquiry()">
                            <i class="fa fa-file"></i>
                            &nbsp;{{
                                $store.state.language.product.add_to_inquiry
                            }}
                        </a>
                    </div>
                </div>
                <div class="d-none d-md-block col-md-7 col-lg-8">
                    <img
                        class="main"
                        :src="product.main_img"
                        :alt="product.name"
                    />
                </div>
            </div>
        </div>
        <div class="container block mt-3">
            <div class="px-0 info">
                <div id="product_tab" class="mb-3">
                    <template
                        v-for="(i, ik) in $store.state.language.product
                            .info_data"
                    >
                        <div
                            class="tab_btn"
                            :class="nowtab == ik ? 'active' : ''"
                            @click="nowtab = ik"
                            v-if="product[ik] && product[ik].length > 0"
                            :key="`tab_${ik}`"
                        >
                            {{
                                $store.state.language.product.info_data[ik] ||
                                    ""
                            }}
                        </div>
                    </template>
                    <div
                        class="tab_btn"
                        :class="nowtab == 'milling_head' ? 'active' : ''"
                        @click="nowtab = 'milling_head'"
                        v-if="
                            milling_head.standard.length > 0 ||
                                milling_head.option.length > 0
                        "
                    >
                        {{ $store.state.language.product.milling_head.title }}
                    </div>
                </div>
                <template
                    v-for="(i, ik) in $store.state.language.product.info_data"
                >
                    <div
                        :key="`info_${ik}`"
                        v-if="product[ik] && product[ik].length > 0"
                    >
                        <transition name="fade">
                            <row
                                class="tab_content"
                                v-model="product[ik]"
                                v-show="nowtab == ik"
                            ></row>
                        </transition>
                    </div>
                </template>
                <transition name="fade">
                    <div class="tab_content" v-show="nowtab == 'milling_head'">
                        <div
                            v-for="t in ['standard', 'option']"
                            v-show="milling_head[t].length > 0"
                            :key="'milling_head_' + t"
                        >
                            <div class="info_title">
                                {{
                                    $store.state.language.product.milling_head[
                                        t
                                    ]
                                }}
                            </div>
                            <span
                                v-for="(m, mk) in milling_head[t]"
                                :key="`btn_${mk}`"
                            >
                                <a
                                    class="btn button"
                                    @click="show_milling_head = m"
                                >
                                    {{ m.name }} </a
                                >&nbsp;
                            </span>
                        </div>
                        <p>&nbsp;</p>
                        <p>&nbsp;</p>
                        <div
                            class="modal fade show"
                            tabindex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalLabel"
                            aria-hidden="true"
                            style="display: block;"
                            v-if="show_milling_head"
                            @click="show_milling_head = false"
                        >
                            <div
                                class="modal-dialog  modal-xl"
                                role="document"
                                @click.stop
                            >
                                <div class="modal-content">
                                    <div class="modal-header">
                                        <h5
                                            class="modal-title"
                                            id="exampleModalLabel"
                                        >
                                            {{ show_milling_head.name }}
                                        </h5>
                                        <button
                                            type="button"
                                            class="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                            @click="show_milling_head = false"
                                        >
                                            <span aria-hidden="true"
                                                >&times;</span
                                            >
                                        </button>
                                    </div>
                                    <div class="modal-body">
                                        <row
                                            v-model="show_milling_head.content"
                                        ></row>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>
                <div class="text-center">
                    <div id="btop" class="btn_top" @click="goto()">
                        {{ $store.state.language.product.back_to_top }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss">
.tab_btn {
    cursor: pointer;
}
.tab_content {
    min-height: 100px;
}
.video_embed > * {
    width: 100%;
    height: 60vh;
}
.video_loading {
    border: 16px solid #f3f3f3;
    border-radius: 50%;
    border-top: 16px solid #3498db;
    width: 120px;
    height: 120px;
    -webkit-animation: loading_spin 3s linear infinite; /* Safari */
    animation: loading_spin 3s linear infinite;

    position: absolute;
    z-index: -1;
    top: calc(50% - 60px);
    left: calc(50% - 60px);
}
/* Safari */
@-webkit-keyframes loading_spin {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}
@keyframes loading_spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.product .block {
    background-color: #fff;

    .s_info {
        color: #666;

        ul {
            padding-left: 0em;
        }
    }

    .main {
        width: 100%;
    }

    .info {
        padding-top: 1.5em;
        padding-bottom: 1em;

        .info_title {
            font-weight: bold;
            color: #000;
            font-size: 2em;
            padding: 0.5em 0;
            margin-top: 0.5em;
        }

        ul {
            padding-left: 0em;
        }

        table {
            tr {
                th {
                    background-color: #0060ae;
                    color: #ffffff;
                    padding: 8px;
                    font-size: 0.8em;
                    font-weight: bold;
                }

                td {
                    border-bottom: 1px solid #dddddd;
                    padding: 8px;
                    font-size: 0.8em;
                }

                &:hover {
                    background-color: #f2f2f2;
                }
            }
        }
    }

    .download_btn {
        margin: 5px 10px 5px 0;
    }

    .inquiry_btn {
        cursor: pointer;
        background-color: #000000;
        padding: 0.5em 1.5em;
        color: #fff;
        text-decoration: none;
        display: inline-block;
        border-radius: 0;
        transition: background 0.3s;
        user-select: none;

        &:hover {
            background-color: #333333;
        }
    }

    .btn_top {
        cursor: pointer;
        padding: 0px 30px;
        margin: 30px auto 5px;
        font-weight: bold;
        display: inline-block;
        color: #a1a1a1;
        border: 2px solid #a1a1a1;

        &:hover {
            color: #f27f00;
            border: 2px solid #f27f00;
        }
    }
}
.modal {
    background-color: rgba(0, 0, 0, 0.5);
}
</style>
<script>
import row from "../../components/row.vue";
import Vue from "vue/dist/vue.esm.js";
import v from "../../components/video.vue";
Vue.component("v", v);
export default {
    data() {
        return {
            product: {
                main_video: []
            },
            milling_head: {
                standard: [],
                option: []
            },
            nowtab: "",
            show_milling_head: false
        };
    },
    watch: {
        $route() {
            this.init();
        },
        show_milling_head() {
            if (!this.show_milling_head) {
                document.getElementsByTagName("body")[0].style = "";
            } else {
                document.getElementsByTagName("body")[0].style =
                    "overflow:hidden";
            }
        }
    },
    mounted() {
        this.init();
    },
    computed: {
        get_s_info() {
            let html = "",
                info = this.$nl2br(this.product.s_info).replaceAll(
                    "<br>",
                    "</li><li>"
                );
            html = `
                <ul>
                    <li>${info}</li>
                </ul>
                `;
            return html;
        }
    },
    methods: {
        init() {
            let vue = this;
            //
            window.scroll(0, 0);
            // 機台資料
            vue.product = vue.$store.state.data.product.find(p => {
                return p.id == vue.$route.params.id;
            });
            // 二軸頭
            vue.milling_head.standard = [];
            if (vue.product.id_milling_head) {
                vue.product.id_milling_head.forEach(mhid => {
                    let item = vue.$store.state.data.milling_head.find(mh => {
                        return mh.id == mhid;
                    });
                    vue.milling_head.standard.push(item);
                });
            }
            vue.milling_head.option = [];
            if (vue.product.id_milling_head_opt) {
                vue.product.id_milling_head_opt.forEach(mhid => {
                    let item = vue.$store.state.data.milling_head.find(mh => {
                        return mh.id == mhid;
                    });
                    vue.milling_head.option.push(item);
                });
            }
            // 預設開合
            vue.nowtab = "";
            for (let ikey in vue.$store.state.language.product.info_data) {
                if (
                    vue.nowtab == "" &&
                    vue.product[ikey] &&
                    vue.product[ikey].length > 0
                ) {
                    vue.nowtab = ikey;
                }
            }
        },
        goto() {
            window.scroll(0, 0);
            /*let tag = 'product_tab';
                if (document.getElementById(tag)) {
                    let goto = document.getElementById(tag).offsetTop - 200;
                    window.scroll(0, goto);
                }*/
        },
        get_inquiry() {
            let output = "";
            // Cookie 處理
            const value = `; ${document.cookie}`;
            const parts = value.split(`; inquiry=`);
            const web_inq = parts
                .pop()
                .split(";")
                .shift();
            console.log("Website Inquiry=" + web_inq);
            output = web_inq || "[]";
            return JSON.parse(output);
        },
        add_inquiry() {
            let vue = this,
                pid = vue.$route.params.id,
                inq_data = vue.get_inquiry();
            if (inq_data.indexOf(pid) == -1) {
                inq_data.push(pid);
            }
            inq_data.sort();
            document.cookie = "inquiry=" + JSON.stringify(inq_data);
            //
            vue.$swal.fire({
                icon: "success",
                toast: true,
                title:
                    "<u>" +
                    vue.product.model +
                    " " +
                    vue.product.name +
                    "</u> " +
                    vue.$store.state.language.product.inquiry_message,
                position: "bottom-right",
                showConfirmButton: false,
                background: "rgba(255, 255, 255, 0.8)",
                timer: 5000,
                didOpen() {
                    vue.$router.push("/inquiry");
                }
            });
        },
        showVideo(link, embed) {
            let subvue = "",
                html = `
                <div id="v_player">
                    <div class="video_loading"></div>
                    <div v-if="embed==''"><v :src="link"></v></div>
                    <div class="video_embed" v-html="embed" v-else></div>
                </div>
                `;
            this.$swal
                .fire({
                    showConfirmButton: false,
                    showCloseButton: true,
                    title: " ",
                    width: "90vw",
                    html: html,
                    didOpen() {
                        subvue = new Vue({
                            el: "#v_player",
                            data: {
                                link: link,
                                embed: embed || ""
                            }
                        });
                    }
                })
                .then(() => {
                    subvue.$destroy();
                });
        }
    },
    components: {
        row
    }
};
</script>
