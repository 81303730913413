<template>
    <div id="video_div" class="video" :class="playing ? '' : 'video_pause'">
        <video
            ref="video"
            autoplay
            name="media"
            :src="src[0]"
            :type="`video/${exc}`"
            @click="play_pause()"
            @timeupdate="update_time()"
            @canplay="loaded()"
            v-if="video_src != ''"
        ></video>
        {{ video_src == "" ? "找不到支援格式的影片" : "" }}
        <i class="fa fa-play" aria-hidden="true" @click="play_pause()"></i>
        <div class="progressbar">
            <input type="range" min="0" max="100" v-model="timepercent" />
        </div>
    </div>
</template>
<style lang="scss" scoped>
.video {
    position: relative;
    background: url("/img/loading.gif") center no-repeat;
    min-height: 100px;

    video {
        width: 100%;

        max-height: calc(90vh - 4em);
    }

    .progressbar {
        transition: opacity 0.3s;
        position: absolute;
        opacity: 0;
        bottom: 0;
        width: 100%;
        background-color: #fff;

        input {
            width: 100%;
        }
    }

    &:hover {
        .progressbar {
            opacity: 0.7;
        }
    }

    .fa {
        display: none;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-left: -0.5em;
        margin-top: -0.5em;
        color: rgba(255, 255, 255, 0.8);
        font-size: 2.5em;
        text-shadow: 0 0 20px rgba(0, 0, 0, 0.2), 0 0 10px rgba(0, 0, 0, 0.5);
    }

    &.video_pause .fa {
        display: block;
    }
}
</style>
<script>
export default {
    name: "v",
    data() {
        return {
            video_src: "",
            playing: true,
            currentTime: 0,
            length: 0,
            init: false
        };
    },
    props: {
        src: {
            default() {
                return [];
            }
        }
    },
    mounted() {
        let video_test = document.createElement("video");
        this.video_src = this.src.find(item => {
            let ext = item.split(".");
            return video_test.canPlayType("video/" + ext.pop()) != "";
        });
    },
    computed: {
        exc() {
            return this.video_src.split(".").pop() || "";
        },
        timepercent: {
            get() {
                return (this.currentTime / this.length) * 100;
            },
            set(val) {
                this.$refs.video.currentTime = this.currentTime =
                    (this.length * val) / 100;
            }
        }
    },
    methods: {
        loaded() {
            this.init = true;
            this.length = this.$refs.video.duration;
        },
        play_pause() {
            if (this.$refs.video.paused) {
                this.$refs.video.play();
                this.playing = true;
            } else {
                this.$refs.video.pause();
                this.playing = false;
            }
        },
        update_time() {
            if (typeof this.currentTime != "undefined") {
                this.currentTime = this.$refs.video.currentTime;
            }
        }
    }
};
</script>
